export const METRICS_LOADING = "METRICS_LOADING";
export const METRICS_SUCCESS = "METRICS_SUCCESS";

export type Metrics = {
  id: string;
  dates: string[];
  systemTemp: (number | null)[];
  solarChargeCurrent: (number | null)[];
  batteryVoltage: (number | null)[];
  batteryCurrent: (number | null)[];
  systemState: number;
};

export interface MetricsLoading {
  type: typeof METRICS_LOADING;
}

export interface MetricsSuccess {
  type: typeof METRICS_SUCCESS;
  payload: Array<Metrics>;
}

export type MetricsDispatchTypes = MetricsLoading | MetricsSuccess;
